<template>
  <div class="page-login">
    <c-auth-modal title="Добро пожаловать!" :loading="$var('load')" back>
      <template #sub>
        Войдите, чтобы опубликовать свой товар.
        Нет профиля?
        <n-link :to="{name:'auth.register1'}">Зарегистрироваться</n-link>
      </template>
      <n-form @submit="submit">
        <n-items>
          <n-input title="Телефон" v-bind="$form.input('login')" />
          <n-input title="Пароль" v-bind="$form.input('password')" type="password"/>
          <n-link class="forgot-pass" :to="{name: 'auth.forgot'}"><span>Забыли пароль?</span></n-link>
          <n-button wide color="primary" type="submit">Войти</n-button>
        </n-items>
      </n-form>
    </c-auth-modal>
  </div>
</template>

<script>
export default {
  name: 'PageLogin',
  created() {
    this.$form.init({
      login: '',
      password: '',
    })
    this.$form.rules({
      login: [ 'mask', ],
      password: [ 'required', ],
    })
    this.$form.masks({
      login: 'phone',
    })

    $app.auth.needAuth(false)
  },
  methods: {
    submit() {
      if (this.$form.check()) {
        this.$var('load', true)
        $api.auth.login(this.$form.get()).then((response) => {
          const data = response.data.content
          data.user.store = data.store
          $app.auth.login(data.user, data.token, data.grants)
        }).finally(() => {
          this.$var('load', false)
        })
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.page-login {
  .forgot-pass {
    font-size: 12px;
    color: #AD9A9A;
    display: flex;
    justify-content: flex-end;
    margin: 0 0 20px;
  }
}
</style>
